import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ShareLinks = styled.div`
    a {
        margin-right: 0.5rem;
    }
`;

const Container = styled.a`
    display: inline-block;
    text-decoration: none;
    color: white;
    border: none;
    box-sizing: border-box;
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
    border-radius: 3px;
    cursor: pointer;

    .text {
        margin-left: 0.4rem;
    }
`;

const TwitterContainer = styled(Container)`
    background-color: #00aced;
`;
type TwitterShareLinkProps = {
    url: string,
    text: string,
    via: string
}
export const TwitterShareLink = (props: TwitterShareLinkProps) => {
    const url = useMemo(() => {
        return 'https://twitter.com/share?' + Object.entries(props)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');
    }, []);

    return (
        <TwitterContainer href={url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />
            <span className="text">tweet</span>
        </TwitterContainer>
    );
}

const HatenaContainer = styled(Container)`
    background-color: #00a4de;

    :before {
        content: "B!";
        font-family: Verdana;
        font-weight: bold;
        position: relative;
    }
`;
type HatenaShareLinkProps = {
    url: string
}
export const HatenaShareLink = (props: HatenaShareLinkProps) => {
    const url = useMemo(() => `http://b.hatena.ne.jp/entry/s/${props.url.substr(8)}`, []);
    return (
        <HatenaContainer href={url} target="_blank" rel="noopener noreferrer">
            <span className="text">ブックマーク</span>
        </HatenaContainer>
    );
}

const FacebookContainer = styled(Container)`
    background-color: #3b5998;
`;
type FacebookShareLinkProps = {
    url: string
}
export const FacebookShareLink = (props: FacebookShareLinkProps) => {
    const url = useMemo(() =>
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url)}`
    , []);
    return (
        <FacebookContainer href={url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" />
            <span className="text">share</span>
        </FacebookContainer>
    );
}