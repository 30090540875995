import React, { ComponentProps, useMemo, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout";
import { Badge } from "../components/Badge";
import { RemarkNode } from '../models/remark-node';
import { Article } from '../components/Article';
import { ShareLinks, TwitterShareLink, HatenaShareLink, FacebookShareLink } from '../components/ShareLink';
import { FadeInContainer } from "../components/FadeInContainer";

type Props = ComponentProps<'div'> & {
    data: {
        site: {
            siteMetadata: {
                url: string
            }
        },
        markdownRemark: Pick<RemarkNode, Exclude<keyof RemarkNode, 'fields'>>
    }
};

export default ({ data }: Props) => {
    const [state, setState] = useState('hidden');
    const post = data.markdownRemark;
    const { url } = data.site.siteMetadata;
    const { title, category, date, tags, thumbnail } = post.frontmatter;
    
    const currentUrl = useMemo(() => `${url}posts/${post.id}`, [url, post.id]);

    useEffect(() => { setTimeout(() => setState('visible'), 100) }, [])

    return (
      <Layout
        type="article"
        title={title}
        url={currentUrl}
        image={ !!thumbnail ?  `${url}${ thumbnail.startsWith('/') ? thumbnail.substr(1) : thumbnail }` : `${url}assets/icon.png`}
        description={post.excerpt}>
        <FadeInContainer className={state}>
            <Article>
                <h1 className="title">{title}</h1>
                <section className="sub-container">
                    <Badge primary to={`/categories/${category.toLocaleLowerCase()}`}>
                        {category}
                    </Badge>
                    <span className="publish-date">{date}</span>
                </section>
                {!!thumbnail
                    ? (<section className="image-container">
                        <img className="thumbnail" src={thumbnail} />
                    </section>)
                    : null
                }
                <section className="body" dangerouslySetInnerHTML={{ __html: post.html }} />
                <section className="tags">
                    {(tags || []).map(tag => {
                        return (
                            <Badge to={`/tags/${tag.toLowerCase()}`} className="tag" key={tag} outlined>#{tag}</Badge>
                        );
                    })}
                </section>
                <section className="share">
                    <h4 className="title">Share</h4>
                    <ShareLinks>
                        <TwitterShareLink url={currentUrl} text={title} via="ponday_dev" />
                        <HatenaShareLink url={currentUrl} />
                        <FacebookShareLink url={currentUrl} />
                    </ShareLinks>
                </section>
            </Article>
        </FadeInContainer>
      </Layout>
    );
}

export const query = graphql`
  query($slug: String!) {
    site {
        siteMetadata {
            url
        }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        category
        tags
        date(formatString: "YYYY-MM-DD")
        thumbnail
      }
      excerpt(truncate: true)
    }
  }
`
