import styled from '@emotion/styled';

export const Article = styled.article`
    max-width: 900px;
    margin: 0 auto;

    .title {
        font-size: 1.8rem;
        margin: 1.8rem 0 0 0;
        color: var(--color-black);
    }
    .publish-date {
        font-size: 0.9rem;
        color: var(--color-gray-darker);
        font-weight: bold;
        margin-left: 0.5rem;
    }
    .sub-container,
    .image-container,
    .body,
    .tags {
        padding: 1rem 0;
        color: var(--color-black);
    }
    .thumbnail {
        display: block;
        max-height: 400px;
        margin: 0 auto;
        width: 100%;
        object-fit: contain;
    }
    .tag {
        margin-right: 0.5rem;
    }
    .body {
        font-size: 15px;

        a {
            color: var(--color-primary);
            font-weight: bold;
        }
        h1, h2 {
            border-bottom-style: solid;
            border-bottom-color: rgba(0, 0, 0, 0.2);
        }
        h1 {
            font-size: 1.6rem;
            border-width: 2px;
        }
        h2 {
            font-size: 1.4rem;
            border-width: 1px;
            margin-top: 2.5rem;
        }
        h3 { font-size: 1.3rem; }
        h4 { font-size: 1.2rem; }
        h5 { font-size: 1.0rem; }
        h6 { font-size: 0.8rem; }
        table {
            margin: 0 auto;
            border-spacing: unset;
            width: 90%;
        }
        th, td {
            padding: 0.7rem 1rem 0.3rem 1rem;
            box-sizing: border-box;
        }
        td {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        tr {
            transition: .08s all linear;
        }
        blockquote {
            position: relative;
            margin: 0;
            margin: 0 0 0 1rem;
            padding: 3px 0 3px 2rem;
            background-color: #f1f3f3;
            color: rgba(0, 0, 0, 0.7);
        }
        blockquote::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background-color: #d6dcdc;
        }
        tbody tr:hover {
            background-color: rgba(0, 143, 250, 0.1);
            transition: .14s all linear;
        }
    }

    .share {
        padding: 2rem 0 0 0;

        .title {
            font-size: 1rem;
            color: var(--color-black);
            margin: 0 1rem 0.5rem 0;
        }
    }
`;

